<template>
  <div class="card text-center my-0 mx-auto" style="width: 320px">
    <div class="card-header">
      <h5 class="mb-1">Reset password</h5>
    </div>
    <form v-on:submit.prevent="reset" class="card-body text-left">
        <div class="mb-2">
          <label for="email">Email address</label>
          <input type="email" ref="email" name="email" id="email" v-model="form.email" class="form-control" required />
        </div>
        <div class="mb-2">
          <label for="name">New password</label>
          <input id="password" type="password" class="form-control" name="password" v-model="form.password" required autofocus />
        </div>

        <div class="mb-2">
          <label for="email">Repeat password</label>
          <input id="password_confirmation" type="password" class="form-control" name="password_confirmation" v-model="form.password_confirmation" required />
        </div>
        <div class="mb-2">
          <button type="submit" class="btn btn-success btn-block">Reset password</button>
        </div>
      <div v-if="errors.password" class="alert alert-danger mb-2">
        <div v-for="error in errors.password" :key="error">{{ error }}</div>
      </div>
      <div v-if="errors.token" class="alert alert-danger mb-2">
        <div v-for="error in errors.token" :key="error">{{ error }}</div>
      </div>
      <div v-if="errors.email" class="alert alert-danger mb-2">
        <div v-for="error in errors.email" :key="error">{{ error }}</div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
		email: this.$route.query.email,
		password: "",
		password_confirmation: "",
        token: this.$route.query.token,
      },
      errors: {},
    };
  },
  methods: {
    async reset() {
      try {
        this.errors = {};
        const { data } = await this.$http.post("/reset-password", this.form);
        this.$cookies.set("fp-token", data.token);
        this.$http.defaults.headers["Authorization"] = "Bearer " + data.token;
        localStorage.setItem("member", JSON.stringify(data.member));
        localStorage.setItem("messages", JSON.stringify(data.messages));
        this.$router.push("/");
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
  },
};
</script>